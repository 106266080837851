(function ($) {
  Drupal.behaviors.productAccordionV1 = {
    attach: function (context) {
      var self = this;

      self.init(context);
      $(document).on('quickshop.loaded', function () {
        self.init(context);
      });
    },

    init: function (context) {
      var $template = $('.js-product-accordion--v1', context);
      var $trigger = $('.js-product-accordion__btn', $template);
      var $panel = $('.js-product-accordion__panel', $template);

      // Using .slideToggle() instead of toggling css classes to animate height easier. Hence we should hide content on the js layer
      $panel.hide();

      $trigger.once().click(function () {
        $(this).toggleClass('active');
        $(this).siblings('.js-product-accordion__panel').slideToggle();
      });

      // Show the 1st item in the accordion only if on SPP
      if ($('.product-full').length) {
        $trigger.eq(0).trigger('click');
      }

      // @TODO - this needs to be refactored
      // Show more
      // $('.accordion_panel a.more').on('click', function() {
      //   $(this).parents('.product-full__short_description').slideUp().siblings('.product-full__long_description').slideDown();
      // });
      // Show less
      // $('.accordion_panel a.less').on('click', function() {
      //   $(this).parents('.product-full__long_description').slideUp().siblings('.product-full__short_description').slideDown();
      // });
    }
  };
})(jQuery);
